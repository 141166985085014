@import '../../../../defaults';
@import '../../../../common/base/mixins/typography';
@import '../../../../common/components/text-elipsis/styles.scss';

@media (max-width: 1023.98px) {
  .container.anw-big-image {
    padding-left: 0;
    padding-right: 0;
  }
}

.anw-article-container .relatedPracticeAreas {
  font-size: 1rem;
  margin: 15px 0 25px;
}

@media (max-width: 767.98px) {
  .anw-article-container .anw-author-image {
    height: 35px;
    width: 35px;
  }
}

.anw-article-company-image {
  background-position: center center;
  background-repeat: no-repeat;
  height: 65px;
  width: 65px;
}

.anw-article-aside {
  bottom: 0;
  z-index: 99;
}
