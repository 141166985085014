.anw-ellipsis-one-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.anw-ellipsis-two-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.anw-ellipsis-three-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.anw-ellipsis-four-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.anw-ellipsis-five-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.anw-ellipsis-six-line {
  display: -webkit-box;
  height: inherit;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
